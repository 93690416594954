import React from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyTylorSwiftTemplate = ({ image }) => {
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Taylor Swift"
        description="A global star harnesses Hook Points to delight and engage her audience in a new way"
        image={image}
      />
      <CaseStudyDescriptionSection
        title={
          <h3>
            Singer-songwriter Taylor Swift knows the power of connecting with
            her fans. We helped her <span>leverage</span> Facebook as a Hook
            Point to increase fan interaction online.
          </h3>
        }
        list={
          <ul>
            <li>
              <span>Built a platform</span> to turn any Facebook page into a
              personalized and customized Taylor Swift fan site instantly and
              automatically as a Hook Point
            </li>
            <li>
              <span>Fans engaged and expressed themselves</span> in an exciting
              new way, while Taylor tapped into new channels for interaction and
              growth. Over 30,000 Taylor fan sites were built in a few short
              months.
            </li>
            <li>
              Our technology platforms also helped{" "}
              <span>increase time spent</span> on Taylor’s from less than 45
              seconds up to 26 minutes.
            </li>
            <li>
              We also leveraged Hooks Points to help Taylor reach her{" "}
              <span>first-ever million dollar month</span> in sales from her
              online store
            </li>
          </ul>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-mtv"}
        text="There’s more to share about how we helped Taylor Swift."
        subText="Let’s get to know each other."
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyTylorSwiftTemplate
