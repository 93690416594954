import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyTylorSwiftTemplate from "../modules/caseStudy/CaseStudyTylorSwift.template"

const CaseStudyTylorSwift = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study Tylor Swift" />
      <CaseStudyTylorSwiftTemplate
        image={data.caseStudyTylorSwiftImage.edges}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyTylorSwiftImage: allFile(
      filter: { relativeDirectory: { eq: "caseStudyTylorSwiftImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyTylorSwift
